<template>
  <div>
    
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import WidgetMixin from '../../../../mixins/widgetMixin.js'


export default {
  name: 'BotTest',
  mixins: [WidgetMixin],
  data() {
    return {
      botId: undefined,
      botName: undefined,
      serviceId: undefined,
      versionId: undefined,
      botTrained: false,
      finishedLoading: false
    }
  },
  computed: {
    ...mapGetters('botMaker', ['activeVersion']),
    ...mapState(['lang', 'languageSelected']),
    getUrl() {
      return (
        this.$store.state.config.chat +
        '/#/pages/bot-test?bot=' +
        this.botId +
        '&name=' +
        this.botName +
        '&version=' +
        this.$route.query.versionId
      )
    }
  },
  beforeDestroy() {
    let item = document.querySelector(
      "[scr='https://cdn.jsdelivr.net/npm/vue@2.6.12/dist/vue.js']"
    )
    if (item) item.remove()

    item = document.querySelector('#style-konecta-widget')
    if (item) item.remove()

    item = document.querySelector('#konecta-widget')
    if (item) item.remove()

    item = document.querySelector('#chat-konecta')
    if (item) item.remove()
  },
  methods: {
    ...mapActions('botMaker', [
      'GET_ACTIVE_VERSION',
      'GET_BOT',
      'GET_DIALOGS'
    ])
  },
  async mounted() {
    const botId = this.$route.params.bot
    const botName = this.$route.query.botName
    const serviceId = this.$route.query.serviceId

    if (!botId || !serviceId) {
      this.$router.push({ name: 'bots' })
    }

    this.botId = botId
    this.botName = botName
    this.serviceId = serviceId
    
    try {
      this.$vs.loading({
        text: this.lang.botTest.training[this.languageSelected]
      })
      this.versionId = this.$route.query.versionId
      await this.GET_BOT(this.botId)
      await this.GET_ACTIVE_VERSION([this.serviceId, this.$route.query.version ])
      await this.GET_DIALOGS();
      this.previewWidget(this.botId,this.activeVersion, ()=> {
        this.$vs.loading.close()
      }, true);
    } catch (error) {
      this.finishedLoading = true
      this.$vs.loading.close()
      this.$log.error(error)
    }
  }
}
</script>
