import Request from './request'
import state from '../store/state'
import store from '../store/store'
const backend = store.state.config.konecta

console.log("CONFIG", state.config);

const PATH = {
  readWidget: '/fauna_widget',
  middleware: backend + '/fauna_widget/middleware'
}

/**
 * Get token
 *
 * @returns {String} with the token retrieved from the local storage
 */
const _getToken = () => {
  /** Pending */
  return 'test'
}

const _getWidgetByDomain = body => {
  const domain = body.domain
  const config = {
    headers: {
      'x-company': state.companyId
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.readWidget}?where[domain]=${domain}`,
    config
  )
}

const _createWidgetKona = (botId, domain, widgetFinal) => {
  if (!botId) throw new Error('No botId')
  if (!domain) throw new Error('No domain')
  const config = {
    headers: {
      'x-company': state.companyId,
      'x-key': state.companyAuthKey
    }
  }

  return Request.post(
    `${state.config.konecta}${PATH.readWidget}`,
    {
      domain: domain,
      id: botId,
      data: widgetFinal
    },
    config
  )
}

const _getWidgetByDomainFaunaMiddleware = body => {
  const domain = encodeURIComponent(body.domain)
  const config = {
    headers: {
      'x-company': state.companyId,
      'x-key': state.companyAuthKey,
      'x-forward-url': state.config.konectaWidget,
      'x-forward-path': `/.netlify/functions/readWidget/${domain}`
    }
  }
  return Request.get(PATH.middleware, config)
}

const _getWidgetByBotIdFaunaMiddleware = body => {
  const botId = encodeURIComponent(body.botId)
  const config = {
    headers: {
      'x-company': state.companyId,
      'x-key': state.companyAuthKey,
      'x-forward-url': state.config.konectaWidget,
      'x-forward-path': `/.netlify/functions/readWidgetByBotId/${botId}`
    }
  }
  return Request.get(PATH.middleware, config)
}

const _updateWidgetFaunaMiddleware = (widget_id_e, body) => {
  let widget_id = encodeURIComponent(widget_id_e)
  const config = {
    headers: {
      'x-company': state.companyId,
      'x-key': state.companyAuthKey,
      'x-forward-url': state.config.konectaWidget,
      'x-forward-path': `/.netlify/functions/updateWidget/${widget_id}`
    }
  }

  return Request.post(PATH.middleware, body, config)
}

const _createWidgetFaunaMiddleware = body => {
  const config = {
    headers: {
      'x-company': state.companyId,
      'x-key': state.companyAuthKey,
      'x-forward-url': state.config.konectaWidget,
      'x-forward-path': `/.netlify/functions/createWidget`
    }
  }

  return Request.post(PATH.middleware, body, config)
}

// TODO: Future idea: remove `/fauna_widget/middleware`, using only `/fauna_widget`. Test if it works.
// not sending data: widgetFinal
const _updateWidgetBy_Id = (_id, botId, domain) => {
  if (!_id) throw new Error('No _id')
  if (!botId) throw new Error('No botId')
  if (!domain) throw new Error('No domain')

  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'x-company': state.companyId,
      'x-key': state.companyAuthKey
    }
  }

  return Request.put(
    `${state.config.konecta}${PATH.readWidget}/${_id}`,
    {
      domain: domain,
      id: botId
    },
    config
  )
}

const _getWidgetByBotId = body => {
  const botId = body.botId
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'x-company': state.companyId,
      'x-key': state.companyAuthKey
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.readWidget}?where[id]=${botId}`,
    config
  )
}

const _getWidgetDomains = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'x-company': state.companyId,
      'x-key': state.companyAuthKey
    }
  }
  return Request.get(`${state.config.konecta}${PATH.readWidget}`, config)
}

const _getDefault = (lang, languageSelected) => {
  return {
    messageSize: 'MEDIUM',
    header: {
      title: lang.botMaker.addBot.widgetDefault.header.title[languageSelected],
      subtitle: '',
      color: { text: '#ffffff', bg: '#ff8100' }
    },
    domain: '',
    botId: '',
    url: state.config.konecta,
    presentation: {
      padding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      size: 'SMALL',
      width: {
        desktop: {
          px: 200,
          txt: ''
        },
        mobile: {
          px: 90,
          txt: ''
        }
      },
      height: {
        desktop: {
          px: 0,
          txt: 'auto'
        },
        mobile: {
          px: 0,
          txt: 'auto'
        }
      },
      borderRadius: {
        desktop: {
          bottomLeft: 20,
          bottomRight: 0,
          topLeft: 20,
          topRight: 20
        },
        mobile: {
          bottomLeft: 20,
          bottomRight: 0,
          topLeft: 20,
          topRight: 20
        }
      },
      position: {
        desktop: {
          bottom: 25,
          right: 100
        },
        mobile: {
          bottom: 10,
          right: 10
        }
      },
      text:
        lang.botMaker.addBot.widgetDefault.presentation.text[languageSelected],
      image: `${state.config.konectaWidget}/assets/bubble_logo.svg`,
      color: '#ff8100',
      textColor: '#ffffff',
      backgroundImg: ''
    },
    disableAvatarUser: false,
    chat: {
      message_placeholder:
        lang.botMaker.addBot.widgetDefault.chat.message_placeholder[
        languageSelected
        ],
      datemessage_placeholder:
        lang.botMaker.addBot.widgetDefault.chat.datemessage_placeholder[
        languageSelected
        ],
      noLocationPermissionMessage:
        lang.botMaker.addBot.widgetDefault.chat.noLocationPermissionMessage[
        languageSelected
        ],
      size: 'MEDIUM',
      startMinimized: false,
      enableFiles: false,
      enableAudioMessages: false,
      disableEmojis: false,
      reaction: false,
      fontFamily: 'Roboto',
      userLogo: `${state.config.konectaWidget}/assets/user.svg`,
      color: '#ff8100',
      textColor: '#ff8100',
      welcome_message: {
        text: [
          lang.botMaker.addBot.widgetDefault.welcome_message.text[
          languageSelected
          ]
        ],
        context: { values: [], control: 'buttons' }
      },
      botMessage: { bg: '#f4f7f9', text: '#000000', link: '#000000' },
      userMessage: { bg: '#ff8100', text: '#ffffff', link: '#ffffff' },
      buttons: { bg: '#ff8100', text: '#ffffff', border: '#ff8100' },
      reload_message: null,
      startMessage: false
    },
    features: {
      uploadFile: false,
      reloadChat: false,
      historyChat: true,
      use_emoji: true,
      use_audio: false
    },
    chatLink: {
      text: 'Powered by konecta®',
      link: 'https://kona.tech',
      show: false
    },
    /** Fon Sizes */
    fontSizes: {
      presentation: 13,
      header: {
        title: 16,
        subtitle: 13
      },
      chat: 13,
      placeholder: 13,
      button: 14,
      card: {
        title: 13.6,
        subtitle: 13.6,
        button: 14
      }
    }
  }
}

const _updateWidget = (chatId, body) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    `${state.config.konectaWidget}${PATH.readWidget}/${chatId}`,
    body,
    config
  )
}

export default {
  getWidgetDomains: _getWidgetDomains,
  updateWidget: _updateWidget,
  getWidgetByBotIdFaunaMiddleware: _getWidgetByBotIdFaunaMiddleware,
  getDefault: _getDefault,
  getWidgetByDomainFauna: _getWidgetByDomainFaunaMiddleware,
  getWidgetByDomain: _getWidgetByDomain,
  getWidgetByBotId: _getWidgetByBotId,
  updateWidgetBy_Id: _updateWidgetBy_Id,
  createWidgetKona: _createWidgetKona,
  updateWidgetFauna: _updateWidgetFaunaMiddleware,
  createWidgetFauna: _createWidgetFaunaMiddleware
}
