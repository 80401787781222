import WidgetService from '@/services/fauna.widget.service.js'
import { mapState } from 'vuex'
import state from '@/store/state'

export default {
  computed: {
    ...mapState(['lang', 'languageSelected', 'config'])
  },
  methods: {
    translateCulture(culture) {
        const rel = {
          'es': 'es',
          'en': 'en',
          'pt': 'pt',
          'es-ES': 'es',
          'en-EN': 'en',
          'pt-BR': 'pt',
        };
        return rel[culture] || 'en';
    },
    async createDefaultWidget(botId, culture = '') {
      if (botId) {
        let language = this.languageSelected;
        if(culture) language = this.translateCulture(culture);
        let dW = WidgetService.getDefault(this.lang, language);
        dW.domain = this.getDefaultDomainWidget(botId);
        dW.botId = botId;
        const widget = this.finalData(dW);
        const result = await this.createWidgetFauna(widget);
        return result;
      }
    },
    getDefaultDomainWidget(botId) {
      const baseUrl = state.config.konectaWidget;
      const urlArr = baseUrl.split('https://')
      const url = `${botId}.${urlArr[1]}`;
      return url;
    },
    getBaseUrl() {
      return state.config.konecta;
    },
    finalData(widget) {
      return {
        konectaWidget: widget,
        domain: widget.domain,
        konectaWeb: {
          bubble_content: {
            hasReload: widget.features.reloadChat,
            principal_text: widget.presentation.text,
            isotipo_img: widget.presentation.image,
            color: widget.presentation.color,
            textColor: widget.presentation.textColor
          },
          backgroundImg: widget.presentation.backgroundImg
        },
        header: {
          title: widget.header.title,
          subtitle: widget.header.subtitle,
          color: {
            text: widget.header.color.text,
            bg: widget.header.color.bg
          }
        },
        icons: {
          showAvatar: true,
          chat_agent_image: widget.chat.botLogo,
          chat_user_image: widget.chat.userLogo
        },
        message_placeholder: widget.chat.message_placeholder,
        datemessage_placeholder: widget.chat.datemessage_placeholder,
        noLocationPermissionMessage: widget.chat.noLocationPermissionMessage,
        logoHeader: widget.header.image,
        bubbleUnion: `${state.config.konectaWidget}/assets/vector.svg`, // Always the same, not sure if to keep it as a feature
        suggestionIcon: '',
        botId: widget.botId,
        baseUrl: this.getBaseUrl(),
        konectaUrl: '',
        primaryColor: widget.chat.color,
        textColor: widget.chat.textColor,
        botMessage: {
          bg: widget.chat.botMessage.bg,
          text: widget.chat.botMessage.text,
          link: widget.chat.botMessage.link,
        },
        userMessage: {
          bg: widget.chat.userMessage.bg,
          text: widget.chat.userMessage.text,
          link: widget.chat.userMessage.link,
        },
        welcome_message: widget.chat.welcome_message,
        chat_controls: {
          use_emoji: widget.features.use_emoji,
          use_audio: widget.features.use_audio,
          use_files: false //widget.features.uploadFile
        },
        startMessage: widget.chat.startMessage,
        reload_message: widget.chat.reload_message
          ? widget.chat.reload_message
          : null,
        fingerPrint: widget.features.historyChat,
        integration: '', // No idea where to get it
        chatLink: {
          text: widget.chatLink.text,
          link: widget.chatLink.link,
          show: widget.chatLink.show
        }
      }
    },
    async createWidgetFauna(widgetSettings) {
      let result = await WidgetService.createWidgetFauna(widgetSettings).catch(
        e => {
          console.error(e)
          this.$notify.error(
            this.lang.botMaker.addBot.notifications.couldNotSaveWidget.title[
              this.languageSelected
            ],
            this.lang.botMaker.addBot.notifications.couldNotSaveWidget.text[
              this.languageSelected
            ]
          )
        }
      )
      return result
    }
  }
}
