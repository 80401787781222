import WidgetService from '@/services/fauna.widget.service.js'
import BotMakerService from '@/services/botMaker.service'
import widgetSettingsMixin from '@/mixins/widgetSettingsMixin.js'

export default {
  mixins: [widgetSettingsMixin],
  beforeDestroy() {
    this.$nextTick(() => {
      this.destroyWidget()
    })
  },
  methods: {
    pauseWidget() {
      window.document.dispatchEvent(new Event('destroyWidget'))
      this.destroyWidget()
    },
    checkTrainingStatus(botId, versionId) {
      return new Promise((resolve, reject) => {
        // return BotMakerService.trainVersion(this.versionId)
        return BotMakerService.trainVersion(versionId)
          .then(() => {
            setTimeout(() => {
              resolve(this.checkTrainingStatus(botId, versionId))
            }, 2000)
          })
          .catch(error => {
            if (error.response.status == '304') {
              this.$log.info({
                botId: botId,
                versionId: versionId,
                status: 'TRAINED'
              })
              return resolve('TRAINED')
            }
            this.$log.error(error)
            reject(error)
          })
      })
    },
    previewWidget(botId, versionId, cb, cancelLoading = false, culture = 'en') {
      if (!cancelLoading) this.$vs.loading()

      WidgetService.getWidgetByBotIdFaunaMiddleware({
        botId
      })
        .then(() => {
          this.checkTrainingStatus(botId, versionId)
            .then(() => {
              document.querySelectorAll('style').forEach(e => {
                e.setAttribute('is_not_widget', true)
              })
              this.$nextTick(() => {
                this.loadWidgetFromBotId(botId, versionId, cb)
              })
            })
            .catch(e => {
              console.error(e)
              this.$nextTick(() => {
                this.$vs.loading.close()
              })
            })
        })
        .catch(async e => {
          if (e.response && e.response.status === 400) {
            const createWidget = this.createDefaultWidget(botId)
              .catch(e => {
                return { error: e.message }
              })
              .then(() => {
                this.previewWidget(botId, versionId, cb, true)
                // Try again and return undefined
                return
              })
            if (createWidget && createWidget.error) {
              this.$nextTick(() => {
                this.$vs.loading.close()
              })

              if (cb) return cb({ error: true })
            }
          } else if (e.response && e.response.status === 404) {
            await this.createDefaultWidget(botId, culture);
            return this.previewWidget(botId, versionId, cb, cancelLoading, culture);
          } else {
            this.$nextTick(() => {
              this.$vs.loading.close()
            })

            if (cb) return cb({ error: true })
          }
        })
    },
    destroyWidget() {
      document
        .querySelectorAll(
          "[scr='https://cdn.jsdelivr.net/npm/vue@2.6.12/dist/vue.js']"
        )
        .forEach(e => e.remove())
      document
        .querySelectorAll('#style-konecta-widget')
        .forEach(e => e.remove())
      document.querySelectorAll('#konecta-widget').forEach(e => e.remove())
      document.querySelectorAll('#chat-konecta').forEach(e => e.remove())

      document
        .querySelectorAll(
          `[src="${this.$store.state.config.konectaWidget}/widget/dist/vue-beautiful-chat.umd.min.js"]`
        )
        .forEach(e => e.remove())

      if (document.querySelector("style[is_widget='true']")) {
        document.querySelectorAll("style[is_widget='true']").forEach(e => {
          e.remove()
        })
      }
    },
    loadWidgetFromBotId(botId, versionId, cb) {
      var s = document.createElement('script')
      s.setAttribute('id', 'konecta-widget')
      s.setAttribute('no-background', true)
      if (versionId) {
        s.setAttribute('data-versionId', versionId)
      }
      s.setAttribute('data-baseurl', this.$store.state.config.konectaWidget)
      s.setAttribute('data-botId', botId)
      s.setAttribute(
        'data-staging',
        this.$store.state.config.konectaWidgetStaging || 'true'
      )
      /*** Has to be changed before going into PD */
      s.setAttribute(
        'src',
        `${this.$store.state.config.konectaWidget}/konecta-widget-preview.js`
      )
      document.head.appendChild(s)

      s.onload = () => {
        document.dispatchEvent(new Event('loadWidget'))
        const listenerFunction = () => {
          this.$nextTick(() => {
            this.$vs.loading.close()
          })

          /** Set Widget attribute */
          document.querySelectorAll('style').forEach(e => {
            if (!e.hasAttribute('is_not_widget'))
              e.setAttribute('is_widget', true)
            else {
              e.removeAttribute('is_not_widget')
            }
          })
          document.removeEventListener('widgetLoaded', listenerFunction, true)
          if (cb) cb(true)
        }
        document.addEventListener('widgetLoaded', listenerFunction, true)
      }
    }
  }
}
